import React from "react";

const Input = React.forwardRef((props, ref) => {
  const { icon, rightIcon, ...rest } = props;

  return (
    <div className="form-group form-input">
      {icon ? <span className="form-icon form-icon__left">{icon}</span> : null}
      <input {...rest} className={`form-control ${rest?.className ?? ""}`} ref={ref} />
      {rightIcon ? <span className="form-icon form-icon__right">{rightIcon}</span> : null}
    </div>
  );
});

Input.displayName = "Input";

export default Input;
