import React, { useState, useEffect } from "react";
import { Footer } from "../footer/Footer";
import { Header } from "../header/Header";
import LiveLinksHeader from "../LiveLinksHeader";
import { inter, poppins, oswald } from "../../utils/fonts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MainLayout = ({ children, showLiveLinkHeader = true, className = "" }) => {
  return (
    <div className={`${inter.variable} ${poppins.variable} ${oswald.variable} ${className}`}>
      <Header />
      {showLiveLinkHeader && <LiveLinksHeader />}
      {children}
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default MainLayout;
