import React, { useState, useContext } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import footerLogo from "../../assets/images/footerLogo.png";
import twitter from "../../assets/images/twitter.png";
import youtube from "../../assets/images/youtube.png";
import tikTok from "../../assets/images/tikTok.png";
import instagram from "../../assets/images/instagram.png";
import broadcast from "../../assets/images/broadcast.png";
import Input from "../form/Input";
import { AiOutlineArrowRight, AiOutlineMail } from "react-icons/ai";
import Image from "next/image";
import { AppContext } from "../../context/state";
import Utils from "../../utils/Utils";

export const Footer = () => {
  const router = useRouter();
  const year = new Date().getFullYear();
  const [formError, setFormError] = useState();
  const [formSuccess, setFormSuccess] = useState();
  const [email, setEmail] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const emailSubject = encodeURIComponent("API Access Request");
  const emailBody = encodeURIComponent("👍I would like to learn about API access to ShotQuality");
  const mailTo = "hello@shotquality.com";
  const user = useContext(AppContext)?.userDetail;
  const isTrader = Utils.isTrader(user);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmitClick = async (e) => {
    setFormError();
    e.preventDefault();

    if (!email || email == "") {
      setFormError("Please enter an email");
      return;
    }
    if (!validateEmail()) {
      setFormError("Email is not valid");
      return;
    }
    // const listId = process.env.SENDGRID_FOOTER_LIST_ID;

    // const data = {
    //   list_ids: [listId],
    //   contacts: [{ email: email }],
    // };
    // const response = await fetch("https://api.sendgrid.com/v3/marketing/contacts", {
    //   method: "PUT",
    //   headers: {
    //     Authorization: `Bearer ${process.env.SENDGRID_API_KEY}`,
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // });

    // if (response.status === 202) {
    //   setButtonDisabled(true);
    //   setFormError("Request has been sent successfully!");
    // } else {
    //   setFormError("An error occurred. Please try again");
    // }

    fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/klaviyo/footer/${email}/save`)
      .then((res) => {
        if (res.status === 200) {
          setButtonDisabled(true);
          setFormSuccess("Request has been sent successfully!");
          setFormError("");
        } else {
          setFormError("An error occurred. Please try again");
        }
      })
      .catch((err) => {
        console.error("err", err);
        setFormError("An error occurred. Please try again");
      });
  };
  const validateEmail = () => {
    let lastAtPos = email.lastIndexOf("@");
    let lastDotPos = email.lastIndexOf(".");

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        email.indexOf("@@") == -1 &&
        lastDotPos > 2 &&
        email.length - lastDotPos > 2
      )
    ) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <footer className="footer text-center">
        <div className="container">
          <div className="footerGeneral">
            <div className="row ">
              <div className="col-12 text-center col-md-3 mb-3 order-md-1 order-1">
                <Image src={footerLogo.src} width={286} height={40} style={{ height: "auto" }} alt="Footer logo" />
              </div>
              <div className="col-12 text-center col-md-6 mb-5 order-md-2 order-3">
                {!isTrader && (
                  <div className="text-center">
                    <form
                      action="#"
                      className=""
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "start",
                        justifyContent: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <input
                        type="email"
                        placeholder="Enter your Email"
                        className="input outline"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        style={{ minWidth: "20rem" }}
                        onChange={handleEmailChange}
                        value={email}
                      />
                      <button className="btn btn-orange p-10-15" onClick={onSubmitClick} disabled={buttonDisabled}>
                        Get Free Picks
                      </button>
                    </form>
                    <div className="position-relative w-100 text-left">
                      {formError ? <p className="wrongDetails mb-0 p-0">{formError}</p> : <></>}
                      {formSuccess ? <p className={"formSuccess mb-0 p-0"}>{formSuccess}</p> : <></>}
                    </div>
                  </div>
                )}
              </div>
              <div className="col-12 text-center col-md-3 mb-4 order-md-3 order-2">
                <div className="mobileFooterBtn">
                  <div className="d-flex justify-content-center">
                    <div className="footerSocialIcons">
                      <div className="SocialIcon">
                        <Link className="footerBtn" href="https://twitter.com/shotqualitybets" target={"blank"}>
                          <Image
                            loading="lazy"
                            width={24}
                            height={24}
                            src={twitter.src}
                            className="SocialIconImg"
                            alt="Twitter"
                          />
                        </Link>
                      </div>
                      <div className="SocialIcon">
                        <Link className="footerBtn" href="https://www.tiktok.com/@shotqualitybets" target={"blank"}>
                          <Image
                            loading="lazy"
                            width={24}
                            height={24}
                            src={tikTok.src}
                            className="SocialIconImg"
                            alt="TikTok"
                          />
                        </Link>
                      </div>
                      <div className="SocialIcon">
                        <Link className="footerBtn" href="https://www.instagram.com/shot_quality/" target={"blank"}>
                          <Image
                            loading="lazy"
                            width={24}
                            height={24}
                            src={instagram.src}
                            className="SocialIconImg"
                            alt="Instagram"
                          />
                        </Link>
                      </div>
                      <div className="SocialIcon">
                        <Link
                          className="footerBtn"
                          href="https://www.youtube.com/channel/UCpPaMp8E_Wv1a3dc8mL57dA"
                          target={"blank"}
                        >
                          <Image
                            loading="lazy"
                            width={24}
                            height={24}
                            src={youtube.src}
                            className="SocialIconImg"
                            alt="Youtube"
                          />
                        </Link>
                      </div>
                      <div className="SocialIcon">
                        <Link
                          className="footerBtn"
                          href="https://podcasts.apple.com/us/podcast/shotquality-bets/id1657213908"
                          target={"blank"}
                        >
                          <Image
                            loading="lazy"
                            width={24}
                            height={24}
                            src={broadcast.src}
                            className="SocialIconImg"
                            alt="Apple Podcast"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="middleFooterBlock row">
              <div className="col-md-3 col-12 footerColumn">
                <span className="columnHeader">Your Membership</span>
                <div style={{ marginBottom: "25px" }}>
                  {!isTrader && (
                    <Link href="/welcome" className="answer">
                      <p>Activate your membership</p>
                    </Link>
                  )}
                  <Link href="/login" className="answer">
                    <p>Login to your account</p>
                  </Link>
                  {!isTrader && (
                    <Link href="https://discord.com/invite/shotquality" className="answer">
                      <p>Join Us on Discord</p>
                    </Link>
                  )}
                </div>

                <span className="columnHeader">Premium Tools</span>
                {!isTrader && (
                  <Link href={"/premium-tools"}>
                    <p className="footerLink">Premium Analysis</p>
                  </Link>
                )}
                <Link href={"/build"}>
                  <p className="footerLink">Access the ShotQuality API</p>
                </Link>
                {/* <Link href={"https://shotqualitybets.com/shotquality-data/api/v2/docs#/"}> */}
                <Link href={"https://docs.api.shotquality.com/docs/introduction"} target="_blank">
                  <p className="footerLink">Developers & API Docs</p>
                </Link>
              </div>

              <div className="col-md-3 col-12 footerColumn">
                <span className="columnHeader">NBA Basketball Predictions</span>
                <div style={{ marginBottom: "25px" }}>
                  <Link href={"/nba/shotquality-scores"}>
                    <p className="footerLink">NBA Basketball ScoreCenter</p>
                  </Link>
                  {/* <Link href={"/nba/linevalue"}>
                    <p className="footerLink">NBA Basketball Odds</p>
                  </Link> */}
                  {/* <Link href={"/nba/best-bets"}>
                        <p className="footerLink">NBA Basketball Best Bets</p>
                      </Link>
                      <Link href={"/nba/model-results"}>
                        <p className="footerLink">NBA Basketball Results</p>
                      </Link>
                       <Link href={"/nba/betting-results"}>
                        <p className="footerLink">NBA Betting Analysis</p>
                      </Link> */}
                  <Link href={"/nba/betting-record"}>
                    <p className="footerLink">NBA Betting Trends</p>
                  </Link>
                  <Link href={"/nba/team-standings"}>
                    <p className="footerLink">NBA Basketball Standings</p>
                  </Link>
                  <Link href={"/nba/regression-stats"}>
                    <p className="footerLink">NBA Regression Statistics</p>
                  </Link>
                  <Link href={"/nba/trend-finder"}>
                    <p className="footerLink">NBA Trend Finder</p>
                  </Link>
                  <Link href={"/nba/player-stats"}>
                    <p className="footerLink">NBA Player Analysis</p>
                  </Link>
                  <Link href={"/nba/picks/playerprops"}>
                    <p className="footerLink"> NBA Player Props Research</p>
                  </Link>
                </div>
              </div>
              <div className="col-md-3 col-12 footerColumn">
                <span className="columnHeader">NCAAM Basketball Predictions</span>
                <div style={{ marginBottom: "25px" }}>
                  <Link href={"/ncaa/shotquality-scores"}>
                    <p className="footerLink">NCAAM Basketball ScoreCenter</p>
                  </Link>
                  {/* {!isTrader && (
                    <Link href={"/ncaa/linevalue"}>
                      <p className="footerLink">NCAAM Basketball Daily Projections</p>
                    </Link>
                  )} */}
                  {/* <Link href={"/ncaa/best-bets"}>
                    <p className="footerLink">NCAAM Basketball Best Bets</p>
                  </Link> */}
                  {/* <Link href={"/ncaa/model-results"}>
                    <p className="footerLink">NCAAM Basketball Results</p>
                  </Link> */}
                  {!isTrader && (
                    <>
                      {/* <Link href={"/ncaa/betting-results"}>
                        <p className="footerLink">NCAAM Basketball Betting Analysis</p>
                      </Link> */}
                      <Link href={"/ncaa/betting-record"}>
                        <p className="footerLink">NCAAM Betting Trends</p>
                      </Link>
                    </>
                  )}
                  <Link href={"/ncaa/team-standings"}>
                    <p className="footerLink">NCAAM Basketball Standings</p>
                  </Link>
                  <Link href={"/ncaa/regression-stats"}>
                    <p className="footerLink">NCAAM Regression Stats</p>
                  </Link>
                  <Link href={"/ncaa/trend-finder"}>
                    <p className="footerLink">NCAAM Trend Finder</p>
                  </Link>

                  <Link href={"/ncaa/player-stats"}>
                    <p className="footerLink">NCAAM Player Analysis</p>
                  </Link>
                  {!isTrader && (
                    <Link href={"/ncaa/picks"}>
                      <p className="footerLink">NCAAM Basketball Pregame Projections</p>
                    </Link>
                  )}
                </div>
              </div>
              {/* <div className="col-md-3 col-12 footerColumn">
                <span className="columnHeader">NCAAW Basketball Predictions</span>
                <div style={{ marginBottom: "25px" }}>
                  <Link href={"/ncaaw/shotquality-scores"}>
                    <p className="footerLink">NCAAW Basketball ScoreCenter</p>
                  </Link>
                  <Link href={"/ncaaw/team-standings"}>
                    <p className="footerLink">NCAAW Basketball Standings</p>
                  </Link>
                  <Link href={"/ncaaw/regression-stats"}>
                    <p className="footerLink">NCAAW Regression Stats</p>
                  </Link>
                  <Link href={"/ncaaw/player-stats"}>
                    <p className="footerLink">NCAAW Player Analysis</p>
                  </Link>
                </div>
              </div> */}
              {/**
                <div className="col-md-3 col-12 footerColumn">
                  <span className="columnHeader">March Madness</span>
                  <div style={{ marginBottom: "25px" }}>
                    <Link href={"/marchmadness"}>
                      <p className="footerLink">March Madness</p>
                    </Link>
                    <Link href={"/ncaa/shotquality-scores"}>
                      <p className="footerLink">March Madness Live</p>
                    </Link>
                    <Link href={"/march-madness-tool"}>
                      <p className="footerLink">Bracket Builder</p>
                    </Link>
                    <Link href={"/value-bracket"}>
                      <p className="footerLink">Value Bracket</p>
                    </Link>
                    <Link href={"/march-madness-matchups"}>
                      <p className="footerLink">March Madness Matchups</p>
                    </Link>
                    <Link href={"/march-madness-primer"}>
                      <p className="footerLink">March Madness Primer</p>
                    </Link>
                  </div>
                </div>
 */}
              <div className="col-md-3 col-12 footerColumn">
                <span className="columnHeader">WNBA Basketball Predictions</span>
                <div style={{ marginBottom: "25px" }}>
                  <Link href={"/wnba/shotquality-scores"}>
                    <p className="footerLink">WNBA Basketball ScoreCenter</p>
                  </Link>
                  {/* {!isTrader && (
                    <Link href={"/wnba/linevalue"}>
                      <p className="footerLink">WNBA Basketball Daily Projections</p>
                    </Link>
                  )} */}
                  <Link href={"/wnba/betting-record"}>
                    <p className="footerLink">WNBA Betting Trends</p>
                  </Link>
                  <Link href={"/wnba/team-standings"}>
                    <p className="footerLink">WNBA Basketball Standings</p>
                  </Link>
                  <Link href={"/wnba/regression-stats"}>
                    <p className="footerLink">WNBA Regression Stats</p>
                  </Link>
                  <Link href={"/wnba/trend-finder"}>
                    <p className="footerLink">WNBA Trend Finder</p>
                  </Link>
                  <Link href={"/wnba/player-stats"}>
                    <p className="footerLink">WNBA Player Analysis</p>
                  </Link>
                  <Link href={"/wnba/picks/playerprops"}>
                    <p className="footerLink"> WNBA Player Props Research</p>
                  </Link>
                </div>
              </div>
              <div className="col-md-3 col-12 footerColumn">
                <span className="columnHeader">How ShotQuality Works</span>
                <Link href={"/stats-explained"}>
                  <p className="footerLink">ShotQuality Predictive Models</p>
                </Link>
                <Link href={"/stats-explained#sq-points"}>
                  <p className="footerLink">ShotQuality Predictive Scores</p>
                </Link>
                <Link href={"/stats-explained#adj-sq"}>
                  <p className="footerLink">Adjusted ShotQuality Stats</p>
                </Link>
                <Link href={"/stats-explained#record-luck"}>
                  <p className="footerLink">Calculating Record Luck</p>
                </Link>
                <Link href={"/stats-explained#Rim&3"}>
                  <p className="footerLink">ShotQuality Rim and 3 Pointers Statistics</p>
                </Link>
                <Link href={"/stats-explained#passing-stats"}>
                  <p className="footerLink">ShotQuality Passing Stats</p>
                </Link>
                <Link href={"/stats-explained#misc-stats"}>
                  <p className="footerLink">Other Basketball Statistics</p>
                </Link>
                <Link href={"/glossary"}>
                  <p className="footerLink">Winning Metrics</p>
                </Link>
              </div>
              <div className="col-md-3 col-12 footerColumn">
                <span className="columnHeader">Blog & Basketball Analysis</span>
                {!isTrader && (
                  <>
                    <Link href={"/betcast"}>
                      <p className="footerLink">BetCast</p>
                    </Link>
                    <Link href={"/blog"}>
                      <p className="footerLink">FREE NCAA Pick of the Day</p>
                    </Link>
                    <Link href={"/blog"}>
                      <p className="footerLink">ShotQuality Expert Analysis</p>
                    </Link>
                  </>
                )}
                {/* <Link href={"/ncaa/best-bets"}>
                  <p className="footerLink">NCAAM Basketball Best Bets</p>
                </Link> */}
                {!isTrader && (
                  <>
                    {/* <Link href={"/ncaa/betting-results"}>
                      <p className="footerLink">NCAAM Basketball Betting Analysis</p>
                    </Link> */}
                    <Link href={"/ncaa/picks"}>
                      <p className="footerLink">NCAAM Basketball Pregame Projections</p>
                    </Link>
                  </>
                )}
                {/* <Link href={"/nba/best-bets"}>
                      <p className="footerLink">NBA Basketball Best Bets</p>
                    </Link> */}
                {/* <Link href={"/nba/betting-results"}>
                      <p className="footerLink" style={{ marginBottom: "51px" }}>
                        NBA Basketball Betting Analysis
                      </p>
                    </Link> */}
                <Link href={"/blog"}>
                  <p className="footerLink">ShotQuality Bets Podcast</p>
                </Link>
              </div>
              <div className="col-md-3 col-12 footerColumn">
                <span className="columnHeader">Contact Us</span>
                <div style={{ marginBottom: "25px" }}>
                  <p className="question">👋🏽 Need help?</p>
                  <Link target="_blank" href="https://info.shotquality.com/" className="answer">
                    <p>Help Center</p>
                  </Link>
                  <Link target="_blank" href="/stats-explained" className="answer">
                    <p>Stats Explained</p>
                  </Link>
                  {/* <Link target="_blank" href="https://info.shotquality.com/faqs" className="answer">
                    <p>Basketball Analysis FAQs</p>
                  </Link> */}
                  <Link href="mailto:hello@shotquality.com" className="answer">
                    <p>📧 hello@shotquality.com</p>
                  </Link>
                </div>
                <div>
                  <p className="question">Partner?</p>
                  <Link href="mailto:partners@shotquality.com" className="answer">
                    <p>partners@shotquality.com</p>
                  </Link>
                </div>
              </div>

              <div className="col-md-12 policyWrapper">
                <p style={{ color: "#fff", fontSize: "12px", fontWeight: 400 }}>© Copyright ShotQuality, Inc. 2024</p>
                <div className="footerLink fd-row">
                  <Link href={"/privacy-policy"} className="footerBtn" style={{ marginRight: "24px" }}>
                    Privacy Policy
                  </Link>
                  <Link href={"/terms-of-service"} className="footerBtn" style={{ marginRight: "24px" }}>
                    Terms of Service
                  </Link>
                  <Link href={"/sitemap.xml"} className="footerBtn">
                    Sitemap
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "100%", height: "1px", marginTop: "30px", backgroundColor: "#6B6C72" }} />
        <div className="container">
          <div className="bottomFooterBlock">
            <p>
              <b>DISCLAIMER:</b> This site is 100% for entertainment purposes only and does not involve real money
              betting. There is no gambling offered on the site and no guarantees are made for any specific outcome. If
              you or someone you know has a gambling problem and wants help, call 1-800 GAMBLER. This service is
              intended for adult users only.
            </p>
            <p>
              We are commited to making our website accessible and user friendly to all. While we are consistently
              working to improve, we welcome your feedback and accommodation requests. If you are having difficulty
              accessing or navigation our website, or if you have any suggestions to improve accessability, please email
              our team.
            </p>
            <p>
              <b>AFFILIATE DISCLOSURE:</b> ShotQuality may receive advertising commissions for visits or account
              creation to a sportsbook website.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};
